<template>
  <div class="header-container">
    <div class="banner-box head-nav">
      <div class="top-nav header-main">
        <div class="header default-transition-fast">
          <div class="header-wrapper auto clearfix top-header">
            <div class="header-left">
              <h1 class="animated zoomIn">
                 <router-link to="index">
                <!-- <a  href="javascript:;"
                  ><img
                    src="../assets/picture/default-logo.png"
                    width="122"
                    height="44"
                    alt=""
                /></a> -->
                 </router-link>
              </h1>
              <div class="header-nav">
                <ul>
                  <li class="header-nav-li">
                    <!-- <router-link to="banner">
                      <a class="newest-act-link" href="javascript:;">
                        <i class="newest-act-link-icon"></i>
                        最新活动
                      </a>
                    </router-link> -->
                  </li>
                  <li class="header-nav-li" @click="goHost">
                    <a href="javascript:;" class="cursor-arrow">产品</a>
                  </li>
                  <router-link to="citys">
                  <li class="header-nav-li">
                    <a class="cursor-arrow">VPS城市列表</a>
                  </li>
                   </router-link>
                  <!-- <router-link to="security">
                  <li class="header-nav-li">
                    <a class="cursor-arrow">服务保障</a>

                  </li>
                   </router-link> -->
                  <!-- <router-link to="help">
                  <li class="header-nav-li">
                    <a  href="javascript:;">帮助中心</a>
                  </li>
                   </router-link> -->
                  <router-link to="shop">
                  <li class="header-nav-li">
                    <a class="cursor-arrow">解决方案</a>
                  </li>
                  </router-link>

                   <router-link to="about">
                  <li class="header-nav-li">
                    <a  href="javascript:;">关于我们</a>
                  </li>
                   </router-link>
                   <router-link to="pact">
                  <li class="header-nav-li">
                    <a  href="javascript:;">平台公约</a>
                  </li>
                   </router-link>

                </ul>
              </div>
            </div>

            <div class="nav-right">
              <div
                class="top-user-info-2"
                style="
                  display: flex;
                  aligin-item: center;
                  justify-content: center;
                "
              >
                <router-link to="/register" v-if="!userPhone">
                  <div href="javascript:;" class="btn btn-register">免费注册</div>
                </router-link>
                <router-link to="/contral">
                  <a href="javascript:;" class="btn btn-register">控制台</a>
                </router-link>
                <el-dropdown @command="handleCommand" v-if="userPhone">
                  <a style="line-height:40px;font-size:18px">{{ userPhone }}</a>
                  <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item icon="el-icon-switch-button" command="a">退出登录</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <router-link to="/login" v-if="!userPhone">
                  <a href="javascript:;" class="btn btn-register">登录</a>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      mgyProductPageParam: {
        pageIndex: 1,
        pageSize: 100,
      },
      productList: [],
      userPhone: localStorage.getItem("user")?JSON.parse(window.localStorage.getItem('user')).userPhone:null,
    };
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    ...mapMutations(["loginOut"]),
    getUserInfo(){
    },
    goHost() {
      this.$router.push("/host");
    },
    goAbout(){
      this.$router.push("/about");
    },
    goOrder() {
      this.$router.push("/order");
    },
    getProduct() {
      this.$http
        .post("/mgyProduct/getPageList", this.mgyProductPageParam)
        .then((res) => {
          if (res.data.code == 200) {
            this.productList = res.data.data;
          } else {
            this.$message.error("服务端错误");
          }
        });
    },
    Out() {
      this.loginOut();
      this.$router.push("/login");
    },
    goMy(){
      this.$router.push("/me")
    },
    handleCommand(command) {
      console.log(command)
        if(command == 'a'){
          this.Out()
        }else{
          this.goMy()
        }
      }
  },
};
</script>
<style>
.btn{
  background-color:none;
}
a {
    color:#FFFFFF;
    vertical-align:middle;
    margin-left:10px;
  }
.el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
  }
.header-nav-li:hover .pop-list {
  display: block;
}
.outBtn {
  margin-left: 10px;
  cursor: pointer;
  display: inline-block;
}
.imageSize {
  margin-left: 10px;
  height: 38px;
  display: inline-block;
}
.btn-register{ height:40px !important;
}

</style>
