<template>
  <div id="app">
    <router-view/>
	<!-- <div class="float-image">
      <img src="@/assets/picture/vxmogu.png" alt="浮动图片" />
    </div> -->
  </div>
</template>


<script>
  export default {
	components: {

	},
	name: 'app',
	// 依赖注入
	provide(){
		return {
			app:this
		}
	},
	data() {
		return {

		}
	},
	created() {
		// 初始化用户信息
		 this.$store.commit('initUser')

	},
	methods: {

	},
}
</script>
<style>

@import './assets/css/animate.min.css';
@import './assets/css/common.css';
@import './assets/css/index.css';
@import './assets/css/front.css';
.float-image {
  position: fixed;
  bottom: 150px;
  right: 50px; /* 调整为左侧浮动，或改为 right */
  z-index: 999;
}
.float-image img {
  width: 100px;
  height: auto;
}
</style>
